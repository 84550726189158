<template>
	<div class="mine page">
		<div class="wrapper">
			<div class="header">
					<van-nav-bar class="nav-bar" :title="$t('my.index')">
						<!-- <template #right>
							<van-icon name="setting-o" @click="showSetting()" color="#fff" />
						</template> -->
					</van-nav-bar>
					<div class="user-wrapper">
						<van-image round class="user_img" :src="this.$store.getters.getBaseInfo.ico">
							<template v-slot:loading>
								<van-loading type="spinner" />
							</template>
						</van-image>
						<div class="login-content">
							<div>
								<p class="login-btn" @click="doLogin()">{{ $t("my.usname") }}:{{ this.userInfo.username }}</p>
								<p class="login-label">{{$t("my.1")}}：{{this.userInfo.username1}}</p>
							</div>
							<div class="refresh-btn" @click="refresh()"><van-icon name="replay" color="#ffffff" size="25" /></div>
						</div>
					</div>
				</div>
				<div class="content">
					<div class="wallet">
						<div class="part-2">
							<span>{{ $t("reservation.unit") }}</span>
							<p class="balance van-ellipsis">{{ this.userInfo.showmoney }}</p>
							
						</div>
					</div>
					<div class="finance">
						<div class="finance-item" @click="toService()">
							<span class="text">{{ $t("my.recharge") }}</span>
						</div>
						<div class="finance-item withd" @click="doWithdrawal()">
							<span class="text">{{ $t("my.withdraw") }}</span>
						</div>
					</div>
					<div :style="{ marginTop: menu_top + 'px' }" class="menu">
						<!-- <div class="menu-item" @click="$router.push({ path: '/Personalreport' });">
							
							<div class="menu-item-label">
								<van-image class="menu-item-icon" src="img/mine/baobiao.svg">
									<template v-slot:loading>
										<van-loading type="spinner" />
									</template>
								</van-image>
								<span>{{ $t("my.my_statement") }}</span>
							</div>
							<van-icon name="arrow" />
						</div> -->
						<!-- <div class="menu-item" @click="exit()">
							<div class="menu-item-label">
								<van-image class="menu-item-icon" src="img/mine/mingxi.svg">
									<template v-slot:loading>
										<van-loading type="spinner" />
									</template>
								</van-image>
								<span>{{ $t("my.account_detail") }}</span>
							</div>
							<van-icon name="arrow" />
						</div> -->
						<div class="menu-item" @click="$router.push({ path: '/GameRecord' });">
							<div class="menu-item-label">
								<van-image class="menu-item-icon" src="img/mine/youxi.svg">
									<template v-slot:loading>
										<van-loading type="spinner" />
									</template>
								</van-image>
								<span>{{ $t("my.task_record") }}</span>
							</div>
							<van-icon name="arrow" />
						</div>
					<!-- </div>
					
					<div :style="{ marginTop: menu_top + 'px' }" class="menu">	 -->
						<div class="menu-item" @click="$router.push({ path: '/Infomation' });">
							<div class="menu-item-label">
								<van-image class="menu-item-icon" src="img/mine/user.svg">
									<template v-slot:loading>
										<van-loading type="spinner" />
									</template>
								</van-image>
								<span>{{ $t("my.personal_center") }}</span>
							</div>
							<van-icon name="arrow" />
						</div>
						<div class="menu-item" @click="$router.push({ path: '/Setbank' });">
							<div class="menu-item-label">
								<van-image class="menu-item-icon" src="img/mine/gonggao.svg">
									<template v-slot:loading>
										<van-loading type="spinner" />
									</template>
								</van-image>
								<span>{{ $t("my.my_balance") }}</span>
							</div>
							<van-icon name="arrow" />
						</div>
						<div class="menu-item" @click="$router.push({ path: '/Setting' });">
							<div class="menu-item-label">
								<van-image class="menu-item-icon" src="img/mine/gonggao.svg">
									<template v-slot:loading>
										<van-loading type="spinner" />
									</template>
								</van-image>
								<span>{{ $t("setting.setting") }}</span>
							</div>
							<van-icon name="arrow" />
						</div>
						<div class="menu-item" @click="toNotice()">
							<div class="menu-item-label">
								<van-image class="menu-item-icon" src="img/mine/gonggao.svg">
									<template v-slot:loading>
										<van-loading type="spinner" />
									</template>
								</van-image>
								<span>{{ $t("my.information_announcement") }}</span>
							</div>
							<van-icon name="arrow" />
						</div>
						<!-- <div class="menu-item" @click="toService()">
							<div class="menu-item-label">
								<van-image class="menu-item-icon" src="img/mine/kefu_1.svg">
									<template v-slot:loading>
										<van-loading type="spinner" />
									</template>
								</van-image>
								<span class="menu-item-label">{{ $t("my.online_service") }}</span>
							</div>
							<van-icon name="arrow" />
						</div> -->
						
					</div>
						<div class="pagelogout" @click="loginout()">
							<div class="menu-item-label">
								<span>{{ $t("setting.logout") }}</span>
							</div>
						</div>
				</div>
		</div>
	</div>

</template>

<script>
export default {
	data() {
		return {
			userInfo: {
			},
			menu_top: 40,
			isLoading: false,
		};
	},
	methods: {
		refresh() {
			this.isLoading = true;
			setTimeout(() => {
				this.isLoading = false;
				if (localStorage.getItem('token')) {
					this.$toast(this.$t("reservation.refresh"));
				} else {
					this.$router.push({ path: '/Login' })
				}
			}, 500);
		},
		exit() {
			this.$toast(this.$t("my.finish_task"));
		},
		showSetting() {
			if (localStorage.getItem('token')) {
				this.$router.push({ path: '/Setting' })
			} else {
				this.$router.push({ path: '/Login' })
			}
		},
		toNotice() {
			if (localStorage.getItem('token')) {
				this.$router.push({ path: '/Notice' })
			} else {
				this.$router.push({ path: '/Login' })
			}
		},
		onRefresh() {
			setTimeout(() => {
				this.isLoading = false;
				if (localStorage.getItem('token')) {
					this.getUserInfo();
					this.$toast(this.$t("reservation.refresh"));
				} else {
					this.$router.push({ path: '/Login' })
				}
			}, 500);
		},
		doLogin() {
			if (localStorage.getItem('token')) {
				this.$router.push({ path: '/Infomation' });
			} else {
				this.$router.push({ path: '/Login' })
			}
		},
		doPay() {
			this.$router.push({
				name: 'Recharge',
				params: {
					'balance': this.userInfo.money
				}
			})
		},
		doWithdrawal() {
			this.$http({
				method: 'get',
				url: 'user_get_bank'
			}).then(res => {
				if (res.data.is_bank) {
					this.$router.push("withdraw");
				} else {
					this.$router.push("Setbank");
					this.$toast.fail(this.$t("setting.set_bank"));
				}
			})
		},
		toService() {
			if (this.$store.getters.getBaseInfo.iskefu == 1) {
				this.$router.push("ServiceOnline");
			} else {
				this.$toast.fail(this.$t("setting.forbid"));
			}
		},
		getUserInfo() {
			this.$http({
				method: 'get',
				url: 'user_info'
			}).then(res => {
				if (res.code === 200) {
					this.userInfo = res.data;
					this.menu_top = 15;
					if (this.userInfo.status !== 1) {
						this.$toast(this.$t("video.account_out"));
						localStorage.clear()
						this.$router.push({ path: '/Login' })
					}
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			})
		},
		
		loginout() {
			localStorage.clear()
			this.$router.push({ path: '/Login' });
		}
	},
	created() {
		if(!localStorage.getItem("lang")){
			localStorage.setItem("lang","ms_my")
		}
		if (localStorage.getItem('token')) {
			this.getUserInfo();
		} else {
			this.userInfo.username = this.$t("setting.log_reg");
			this.userInfo.ip = this.$t("setting.more_service");
			this.userInfo.header_img = "img/mine/avatar.png";
		}
	}
};
</script>

<style scoped>
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #f2f2f5;
}

.mine {
	position: relative;
	bottom: 10px;
	background: #f2f2f5;
}

.mine .wrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.nav-bar {
	color: #fff;
}

.mine .header {
	background: #30323b;
	padding-bottom: 40px;
}

::v-deep .van-nav-bar__content {
	height: 100px;
}

::v-deep .van-hairline--bottom::after {
	border-bottom-width: 0px;
}

.mine .header .van-nav-bar .van-icon {
	font-size: 45px;
}

.mine .header .user-wrapper {
	display: flex;
	align-items: center;
	margin: 40px 40px 0px 40px;

}

.mine .user_img {
	height: 130px;
	width: 130px;
}

::v-deep .van-loading__spinner {
	height: 50px;
	width: 50px;
}

::v-deep .van-image__error-icon {
	font-size: 70px;
}

.mine .header .user-wrapper .login-content {
	flex: 1;
	margin-left: 30px;
	display: flex;
	justify-content: space-between;
}

.mine .header .user-wrapper .login-content .login-btn {
	display: inline-block;
	font-size: 28px;
	line-height: 0px;
	color: #fff;
}

.mine .header .user-wrapper .login-content .login-label {
	margin-top: -13px;
	font-size: 28px;
	color: hsla(0, 0%, 100%, .6);
}

.mine .page-bg {
	height: 500px;
	background: linear-gradient(90deg, #7e5678, #e6c3a1);
}

.mine .content {
	position: relative;
	padding: 0 0 200px;
	min-height: 500px;
	background-color: #f2f2f5;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
	color: #ffffff;
	font-size: 35px;
}

.mine .wrapper .content .finance {
	display: flex;
	align-items: center;
	height: 90px;
	background-color: #fff;
	border-radius: 15px;
	box-shadow: 0 1.5px 1px 0 #e4e4e7;
}

.mine .wrapper .content .finance .line {
	width: 3px;
	height: 40px;
	background-color: #ccc;
}

.mine .wrapper .content .finance .finance-item {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.mine .wrapper .content .finance .finance-item .text {
	padding: 30px 0 30px 70px;
	font-size: 30px;
	color: #000;
	font-weight: 500;
	background: url(../images/user/icon10.png) no-repeat left center;
	background-size: 50px;
}
.mine .wrapper .content .finance .withd .text {
	background: url(../images/user/icon12.png) no-repeat left center;
	background-size: 50px;
}

.mine .wrapper .content .finance .finance-item .icon {
	font-size: 50px;
}

.mine .wrapper .content .menu {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	background-color: #fff;
	border-radius: 15px;
	box-shadow: 0 1.5px 1px 0 #e4e4e7;
}

.mine .wrapper .content .menu .menu-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 90px;
	padding-right: 30px;
	border-bottom: solid 1px #efefef;
	margin-left: 25px;
}

.mine .wrapper .content .menu .menu-item .menu-item-label {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 30px;
	color: #868686;
	font-weight: 500;
}

.mine .wrapper .content .menu .menu-item .menu-item-icon {
	margin: 25px 25px 25px 0;
	width: 60px;
	height: 60px;
	-o-object-fit: contain;
	object-fit: contain;
}

.mine .wrapper .content .wallet {
	padding: 0 30px;
	background-color: #2f323bd6;
}

.mine .wrapper .content .wallet .part-1 {
	display: flex;
	align-items: center;
	height: 100px;
}

.mine .wrapper .content .wallet .font-primary-color {
	color: #000;
}

.font-gray {
	color: #868686;
}

.mine .wrapper .content .wallet .part-2 {
	display: flex;
	align-items: center;
	flex-direction: column;
	color: #fff;
	line-height: 45px;
}

.mine .wrapper .content .wallet .part-2 .balance {
	flex: 1;
	color: #fff;
	margin: 10px 0;
}

.mine .wrapper .content .wallet .van-hairline--bottom::after {
	border-bottom-width: 3px;
}

.mine .wrapper .content .wallet .part-2 .refresh-btn {
	margin-left: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	font-size: 30px;
	border-radius: 50%;
	color: #ffffff;
	background-color: #e6c3a1;
}
.refresh-btn {
	padding-top: 15px;
}
.pagelogout{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 90px;
	padding-right: 30px;
	background: #fff;
	margin-top: 50px;
}
</style>
