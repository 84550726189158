<template>
  <van-tabbar
    v-if="show"
    v-model="active"
    active-color="#ffdab5"
    :border="true"
    inactive-color="#979799"
  >
  <!-- 首页 -->
    <van-tabbar-item to="/list">
      <span>{{ $t("foorter.xuanf") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/xined.png' : '/img/footer/xin.png'"
          :alt="$t('foorter.xuanf')"
          v-show="show !== 0"
        />
        <img
          :src="props.active ? '/img/footer/xined.png' : '/img/footer/xin.png'"
          :alt="$t('foorter.xuanf')"
          :class="$t('foorter.xuanf')"
          style="height: 2rem"
          v-show="show === 0"
        />
      </template>
    </van-tabbar-item>
    <!-- 预约 -->
    <van-tabbar-item to="/Game">
      <span>{{ $t("foorter.subscribe") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/gameed.png' : '/img/footer/game.png'"
          :alt="$t('foorter.subscribe')"
          v-show="show !== 1"
        />
        <img
          :src="props.active ? '/img/footer/gameed.png' : '/img/footer/game.png'"
          :alt="$t('foorter.subscribe')"
          :class="$t('foorter.subscribe')"
          style="height: 2rem"
          v-show="show === 1"
        />
      </template>
    </van-tabbar-item>
    <!-- 中间 -->
    <van-tabbar-item to="/Home">
      <span>{{ $t("foorter.index") }}</span>
      <template #icon="props">
        <img class="tui" 
          :src="props.active ? '/img/footer/logo.png' : '/img/footer/logo.png'"
        />
      </template>
    </van-tabbar-item>
    <!-- 视频 -->
    <van-tabbar-item to="/Notice">
      <span>{{ $t("foorter.msg") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/msged.png' : '/img/footer/msg.png'"
          :alt="$t('foorter.msg')"
          v-show="show !== 3"
        />
        <img
          :src="props.active ? '/img/footer/msged.png' : '/img/footer/msg.png'"
          :alt="$t('foorter.msg')"
          :class="$t('foorter.msg')"
          style="height: 2rem"
          v-show="show === 3"
        />
      </template>
    </van-tabbar-item>
    <!-- 我的 -->
    <van-tabbar-item to="/Mine">
      <span>{{ $t("foorter.my") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/usered.png' : '/img/footer/user.png'"
          :alt="$t('foorter.my')"
          v-show="show !== 4"
        />
        <img
          :src="props.active ? '/img/footer/usered.png' : '/img/footer/user.png'"
          :alt="$t('foorter.my')"
          :class="$t('foorter.my')"
          style="height: 2rem"
          v-show="show === 4"
        />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      active: 0
    };
  },
  methods: {},
  watch: {
    $route(to) {
      if (to.name == "home") {
        this.active = 2;
        this.show = true;
      } else if (to.name == "game") {
        this.active = 1;
        this.show = true;
      } else if (to.name == "list") {
        this.active = 0;
        this.show = true;
      } else if (to.name == "Notice") {
        this.active = 3;
        this.show = true;
      } else if (to.name == "mine") {
        this.active = 4;
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
  created() {
    if (this.$route.name == "home") {
      this.active = 2;
      this.show = true;
    } else if (this.$route.name == "game") {
      this.active = 1;
      this.show = true;
    } else if (this.$route.name == "list") {
      this.active = 0;
      this.show = true;
    } else if (this.$route.name == "Notice") {
      this.active = 3;
      this.show = true;
    } else if (this.$route.name == "mine") {
      this.active = 4;
      this.show = true;
    } else {
      this.show = false;
    }
  },
};
</script>

<style lang="less" scoped>
@tabbar-height: 110px;
@tabbar-img: 45px;
.van-tabbar {
  height: @tabbar-height;
  z-index: 9999;
  background: #000;
}
.van-tabbar-item__icon{
  margin-bottom: 20px;
}
.van-tabbar-item__icon img {
  height: @tabbar-img;
}
.van-tabbar-item {
  font-size: 26px;
}
::v-deep .van-tabbar-item--active {
  background: none;
}
.tui {
  width: 3.5rem;
  height: 3.5rem!important;
  margin-top: -7.333vw;
  border-radius: 50%;
  z-index: 10;
}
[class*="van-hairline"]::after {
  border: none !important;
}
</style>
