<template>
	<div class="movie-hall">
		<div class="linear-bg">
			<div class="logo">
				<img class="logo-img" :src="this.$store.getters.getBaseInfo.ico !== undefined ? this.$store.getters.getBaseInfo.ico : ''">
			</div>	
			<div class="pagetitle">
				<ul>
					<li @click="gotoMenu('/Home')">{{ $t('index.tuij') }}</li>
					<li class="sel" @click="gotoMenu('/Video')">{{ $t('index.video') }}</li>
				</ul>
			</div>
		</div>
		<div class="classlist">
			<div class="clitem">
				<van-tabs v-model="active" animated swipeable @change="OnChange">
					<van-tab :title="$t('index.all')"></van-tab>
					<van-tab v-for="(v, key) in videolitem" :key="key" :title="v.name" :name="v.key"></van-tab>
				</van-tabs>
			</div>
		</div>


		
		<div class="hot-recommend-div">
				<van-list v-model="loading" :finished="finished" :immediate-check="false"
					:finished-text="$t('video.no_more')" @load="onLoad">
					<div class="list-item">
						<div class="movie-list-item" v-for="(v, key) in videolist" :key="key"
							@click="toPlayVideo(v.id)">
							<van-image class="cover_img" round :src="v.vod_pic">
								<template v-slot:loading>
									<van-loading type="circular" />
								</template>
							</van-image>
							<div class="movie-list-item-bottom">
								<div class="movie-time-div">
									<span>{{ v.vod_name }}</span>
									<!-- <span>{{$t("video.play")}}:{{v.count}}</span> -->
								</div>
							</div>
						</div>
					</div>
				</van-list>

			</div>
	</div>
</template>

<script>
import { Toast } from 'vant';
export default {
	data() {
		return {
			active: 0,
			isLoading: false,
			count: 0,
			loading: false,
			finished: false,
			refreshing: false,
			videolitem: [],
			videolist: [],
			number: 0,
			page: 0,
			videoSwiperOption: {
				slidesPerView: 'auto',
				spaceBetween: 0,
				slidesPerGroup: 1,
			}
		};
	},
	methods: {
		gotoMenu(router) {
			this.$router.replace(router)
		},
		getVideoClass() {
			this.$http({
				method: 'get',
				url: 'video_class'
			}).then(res => {
				console.log(res)
				this.videolitem = res.data;
			})
		},
		toPlayVideo(id) {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' })
			} else {
				this.$router.push({ path: '/PlayVideo?id=' + id })
			}

		},
		itemChange() {
			this.active = this.$refs.swiper.swiper.activeIndex
			this.OnChange()
		},
		getVideoList() {
			this.$http({
				method: 'get',
				data: { id: this.active, page: this.page },
				url: 'video_list'
			}).then(res => {
				this.videolist = this.videolist.concat(res.data.data);
				console.log(this.videolist);
				this.count = res.data.count;
				this.page++;

			})
		},
		onLoad() {
			this.getVideoList();
			let timer = setTimeout(() => {
				if (this.refreshing) {
					this.videolist = [];
					this.refreshing = false;
				}
				this.loading = false;
				if (this.videolist.length === this.count) {
					this.finished = true;
				}
				this.finished && clearTimeout(timer);//清除计时器
			}, 500);
		},
		OnChange() {
			this.videolist = [];
			this.number = 0;
			this.page=1;
			this.count = 0;
			this.getVideoList();//获取视频列表

		},
		onRefresh() {
			this.finished = false;
			this.loading = true;
			this.onLoad();
			this.isLoading = false;
			Toast(this.$t("reservation.refresh"));
		},
	},
	created() {
		this.getVideoClass();//获取视频类目
		this.OnChange()
	}
};
</script>

<style lang='less' scoped>

.movie-hall {
	background: #1b1b1b;
	padding-bottom: 120px;
}
.linear-bg {
	position: relative;
    padding: .1875rem 0;
    box-sizing: border-box;
	background: #2c2b29;
	.logo{
		height: 2.5rem;
		position: absolute;
		top: 10px;
		left: 10px;
		width: 30%;
		text-align: center;
		padding-top: 5px;
		img{
			height: 90%;
		}
	}
	.pagetitle{
		margin: 0 5.5rem;
		height: 80px;
		width: auto;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		ul{
			height: 1.625rem;
			line-height: 1.625rem;
			border-top: 0.0625rem solid #eacc9b;
			border-bottom: 0.0625rem solid #eacc9b;
			border-radius: 0.875rem;
			width: 7.375rem;
			margin: 0.375rem auto;
			display: flex;
			justify-content: space-evenly;
			li{
				font-size: 0.8125rem;
				font-family: PingFang SC;
				font-weight: 500;
				color: #FFCC99;
				flex: 1;
				text-align: center;
			}
			li:first-child{
				border-left: 1px solid #eacc9b;
				border-top-left-radius: 0.8125rem;
				border-bottom-left-radius: 0.8125rem;
			}
			li:nth-child(2){
				border-right: 1px solid #eacc9b;
				border-top-right-radius: 0.8125rem;
				border-bottom-right-radius: 0.8125rem;
			}
			.sel{
				color: #855A2E;
				background: #FFCC99;
			}
		}
	}
}
.classlist{
	padding: 0 20px;
	.clitem{
		display: flex;
		justify-content: space-between;
		width: 100%;
		overflow: hidden;
	}
}

::v-deep .van-tabs__nav {
	background:none;
}

::v-deep .van-tab--active {
	padding: 0 0.9375rem;
    background: #ffcc99;
    border-radius: 30px;
    color: #855a2e;
}

::v-deep .van-tab--active span {
	color: #855a2e;
}

::v-deep .van-tab {
	color: #aaa;
	font-size: 25px;
	height: 60px;
}

::v-deep .van-tabs__line {
	display: none;
}

::v-deep .van-tabs--line .van-tabs__wrap {
	height: 80px;
}

::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
	margin-top: 10px;
}

::v-deep .van-hairline--bottom::after {
	border-bottom-width: 0px;
}

.video_swiper {
	width: 100%;
	flex: 1;

	.swiper-slide {
		flex-shrink: 0;
		flex-grow: 0;
		flex-basis: 100%;
		justify-content: center;
		height: 100%;
		position: relative;
		transition-property: transform;
	}
}

.movie-list-tab {
	overflow: auto;
	height: 100%;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
	color: #000;
	font-size: 35px;
}

.movie-list-tab .hot-recommend-div {
	height: 100%;
	margin: 10px auto;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	//overflow: auto;
}

.list-item {
	display: flex;
	width: calc(100% - 50px);
	margin: 10px auto;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
}

.list-item .movie-list-item:nth-child(odd) {
	margin-right: 20px;
}

.movie-list-item .cover_img {
	border-radius: 20px;
	width: 335px;
	height: 290px;
}

.movie-list-item {
	margin-bottom: -10px;
}

.list-item .movie-list-item-bottom {
	position: relative;
	width: 335px;
	bottom: 2.5rem;
}

.list-item .movie-list-item-bottom .movie-time-div {
	background-color: rgba(0, 0, 0, .4);
}

.list-item .movie-list-item-bottom>div {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.list-item .movie-list-item-bottom .movie-time-div .van-count-down {
	color: #fff;
}

.list-item .movie-list-item .movie-time-div span:first-child {
	// overflow: hidden;
	// white-space: nowrap;
	//width: 180px;
	padding-left: 8px;
	font-size: 25px;
}

.list-item .movie-time-div {
	color: #fff;
	border-radius: 0 0 20px 20px;
	height: 2.3rem;
}
</style>
