<template>
	<div class="container page">
		<van-nav-bar :title="this.lottery.name" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
			<!-- <template #right>
				<div class="right" @click="changegame=true">切换任务</div>
			</template> -->
		</van-nav-bar>
		<!-- <van-popup v-model="changegame" position="top" class="changegame">
			<div></div>
		</van-popup>	 -->
		<div class="notice-bar">
			<van-notice-bar class="notice-swipe" left-icon="bullhorn-o" color="#FFCC99" :text="this.notice" />
			<div class="linear-gradient"></div>
		</div>
		<div class="record">
			<div class="period">
				<!-- <van-image class="cover" :src="this.lottery.ico">
					<template v-slot:loading>
						<van-loading type="spinner" />
					</template>
				</van-image> -->
				<div class="next-number">
					<span>{{ this.lottery.next_expect }}{{ $t("reservation.num") }}</span>
					<van-count-down :time="time" @finish="check()" />
				</div>
				<div class="period-number">
					<span>{{ this.lottery.now_expect }}{{ $t("reservation.win_num") }}</span>
					<p>{{ this.size }} {{ this.double }}</p>
				</div>

			</div>
			<div class="recent">
				<van-icon name="arrow-down" :class="{ up: active, down: !active }"
					@click="active ? active = false : active = true" />
			</div>
		</div>
		<div class="wrapper">
			<div class="options-bar">
				<div class="game">
					<div class="tit">{{ $t("reservation.qdlist") }}</div>
					<div class="sumValueTwoSides">
						<div class="rectangle large" :class="{ active: choose[v.type] }"
							v-for="(v, key) in lottery_peilv_list" :key="key" @click="choosePlay(v.type, v.name);">
							<div class="wrapper">
								<div class="content">
									<p class="name-text large">{{ v.name }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="bottom-bar">
				<div class="bar">
					<div class="left">
						<div class="item" @click="clearChooes(v.type)">
							<span class="text">{{ $t("reservation.clear_order") }}</span>
						</div>
					</div>
					<div class="mid">
						<div class="part">
							<span>{{ $t("reservation.total") }}</span>
							<span class="number">{{ this.formData.length }}</span>
							<span>{{ $t("reservation.note") }}</span>
						</div>
						<div class="part">
							<span>{{ $t("reservation.total") }}</span>
							<span class="number">{{ this.formData.length * this.money }}</span>
							<span>{{ $t("reservation.unit") }}</span>
						</div>
					</div>
					<div class="right" @click="jiesuan()">
						{{ $t("reservation.submit") }}
					</div>
				</div>
				<div class="inputbox active">
					<div class="amount-wrapper">
						<van-field v-model="money" type="digit" />
						<span class="label">{{ $t("reservation.unit") }}</span>
						<div class="money">
							<span class="text">{{ $t("reservation.available_balance") }}：</span>
							<span class="text num">{{ this.userInfo.showmoney }}</span>
						</div>
					</div>
				</div>
			</div>
			<van-popup v-model="jiesuanpage" get-container="body">
				<div class="confirm-order-modal">
					<div class="head van-hairline--bottom">
						<p class="text">{{ $t("reservation.task_list") }}</p>
					</div>
					<ui class="list">
						<li v-for="(v, key) in formData" :key="key" class="lise-item van-hairline--bottom">
							<div class="main">
								<p class="bet-name">{{ v.name }}</p>
								<p class="detail-text">1{{ $t("reservation.note") }}X{{ money
									}}{{ $t("reservation.unit") }}={{
			money }}{{ $t("reservation.unit") }}</p>
							</div>
							<van-icon @click="clearChooes(v.type)" name="close" />
						</li>
					</ui>
					<div class="sub-bar">
						<van-button class="item cancel-btn" type="default"
							@click="allClear()">{{ $t("reservation.clear_order") }}</van-button>
						<van-button class="item sub-btn" type="default"
							@click="doSub">{{ $t("reservation.submit") }}</van-button>
					</div>
				</div>
			</van-popup>
			<van-popup v-model="active" position="top" :style="{ height: '70%' }">
				<div class="wrapper">
						<div class="item">
							<div class="left font-weight">{{ $t("reservation.num") }}</div>
							<div class="right font-weight">{{ $t("reservation.dxiao") }}</div>
							<div class="right font-weight">{{ $t("reservation.dshuang") }}</div>
							<div class="right font-weight">{{ $t("reservation.dtime") }}</div>
						</div>
						<div class="item" v-for="(v, key) in lottery_list" :key="key">
							<div class="left font-weight">{{ v.expect }}</div>
							<div class="right font-weight">
								<span class="res-des middle">{{ parseInt(v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11 ? "1번" : "2번" }}</span>
							</div>
							<div class="right font-weight">
								<span class="res-des middle">{{ (v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0 ? "4번" : "3번"}}</span>
							</div>
							<div class="right font-weight">
								<span class="res-des middle">{{ v.update_time }}</span>
							</div>
						</div>
					</div>
			</van-popup>
		</div>
	</div>
</template>

<script>
var time;
var count = 0;
export default {
	data() {
		return {
			jiesuanpage: false,
			choose: {
				"1번": false,
				"2번": false,
				"3번": false,
				"4번": false,
			},
			playgame: false,
			shopping: false,
			isLoading: false,
			play: {},
			lottery_peilv_list: {},
			lottery_list: {},
			active: false,
			userInfo: {},
			lottery: {},
			shanzi_1: "0",
			shanzi_2: "0",
			shanzi_3: "0",
			sum: 0,
			size: "",
			double: "",
			time: 0,
			shopchoose: this.$t("reservation.no_choose"),
			gameitem: "",
			formData: [],
			money: "",
			notice:this.$t("index.loading"),
			changegame:false
		};
	},
	methods: {
		back() {
			return window.history.back();
		},
		getNotice(data) {
			this.notice = data.notice;
		},
		getBasicConfig() {
			this.$http({
				method: 'get',
				url: 'sys_config'
			}).then(res => {
				this.getNotice(res.data);//获取公告
			})

		},
		doSub() {
			if (this.money === "0") {
				this.$toast(this.$t("reservation.money_err"));
				return false;
			}
			if (this.formData.length === 0) {
				this.$toast(this.$t("reservation.choose_num"));
				return false;
			} else if (this.money === "") {
				this.$toast(this.$t("reservation.price_place"));
				return false;
			} else {
				if (this.userInfo.money - (this.money * this.formData.length) < 0) {
					this.$toast(this.$t("reservation.balance_enough"));
					return false;
				} else {
					this.$http({
						method: 'post',
						data: {
							item: this.gameitem,
							money: this.money,
							lid: this.lottery.id,
							mid: this.userInfo.id,
							expect: this.lottery.now_expect
						},
						url: 'game_place_order'
					}).then(res => {
						if (res.code === 200) {
							this.$toast(res.msg);
							this.allClear();
							this.getUserInfo();
						} else if (res.code === 401) {
							this.$toast(res.msg);
						}
					})
					return true;
				}
			}
		},
		allClear() {
			for (var i = 0; i < this.formData.length; i++) {
				this.choose[this.formData[i]['type']] = false;
			}
			this.formData.length = 0;
			this.money = "";
			this.shopchoose = this.$t("reservation.no_choose");
			this.gameitem = "";
			this.shopping = false;
			this.jiesuanpage = false;
		},
		jiesuan() {
			if (this.formData.length === 0) {
				this.$toast(this.$t("reservation.choose_num"));
				return false;
			} else if (this.money === "") {
				this.$toast(this.$t("reservation.price_place"));
				return false;
			}
			else {
				this.jiesuanpage ? this.jiesuanpage = false : this.jiesuanpage = true;
			}

		},
		clearChooes(type) {
			for (var i = 0; i < this.formData.length; i++) {
				if (type === this.formData[i]['type']) {
					this.formData.splice(i, 1)
					this.choose[type] = false;
				}
			}
			if (this.formData.length >= 1) {
				for (var j = 0; j < this.formData.length; j++) {
					if (j === 0) {
						this.shopchoose = this.formData[j]['name'];
						this.gameitem = this.formData[j]['type'];
					} else {
						this.shopchoose += "," + this.formData[j]['name'];
						this.gameitem += "," + this.formData[j]['type'];
					}
				}
			} else {
				this.shopchoose = this.$t("reservation.no_choose");
				this.gameitem = "";
				this.shopping = false;
			}
			if (this.formData.length === 0) {
				this.jiesuanpage = false;
			}
		},
		choosePlay(type, name) {
			console.log(123)
			if (this.choose[type] === true) {
				this.choose[type] = false;
				for (var i = 0; i < this.formData.length; i++) {
					if (type === this.formData[i]['type']) {
						this.formData.splice(i, 1)
					}
				}
			} else if (this.choose[type] === false) {
				this.formData.push({ 'name': name, 'type': type })
				this.choose[type] = true;
			}
			if (this.formData.length === 1) {
				this.shopping = true;
			}
			if (this.formData.length >= 1) {
				for (var j = 0; j < this.formData.length; j++) {
					if (j === 0) {
						this.shopchoose = this.formData[j]['name'];
						this.gameitem = this.formData[j]['type'];
					} else {
						this.shopchoose += "," + this.formData[j]['name'];
						this.gameitem += "," + this.formData[j]['type'];
					}
				}
			} else {
				this.shopchoose = this.$t("reservation.no_choose");
				this.gameitem = "";
				this.shopping = false;
			}

		},
		check() {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' })
			} else {
				time = window.setInterval(() => {
					setTimeout(() => {
						this.getUserInfo();
						this.getLotteryInfo();
						this.getLotteryList();
						count++;
						if (count > 5) {
							clearInterval(time);
							count = 0;
						}
					}, 0)
				}, 300)
			}
		},
		onRefresh() {
			setTimeout(() => {
				this.$toast(this.$t("reservation.refresh"));
				this.getLotteryList();
				this.isLoading = false;
			}, 200);
		},
		getUserInfo() {
			this.$http({
				method: 'get',
				url: 'user_info'
			}).then(res => {
				if (res.code === 200) {
					this.userInfo = res.data;
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			})
		},
		getLotteryPeilv() {
			this.$http({
				method: 'get',
				data: { id: this.$route.query.id },
				url: 'lottery_get_peilv'
			}).then(res => {
				if (res.code === 200) {
					this.lottery_peilv_list = res.data;
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			})
		},
		getLotteryList() {
			this.$http({
				method: 'get',
				data: { key: this.$route.query.key },
				url: 'lottery_get_one_list'
			}).then(res => {
				if (res.code === 200) {
					this.lottery_list = res.data;
					console.log('lottery_list:')
					console.log(res.data)
					this.getLotteryPeilv();
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			})
		},
		getLotteryInfo() {
			this.$http({
				method: 'get',
				data: { key: this.$route.query.key },
				url: 'lottery_get_info'
			}).then(res => {
				if (res.code === 200) {
					if (parseFloat(this.userInfo.money) < parseFloat(res.data.condition)) {
						this.$toast(this.$t("reservation.contact_admin"));
						this.$router.push({ path: '/Home' })
						return false;
					}
					this.lottery = res.data;
					this.time = res.data.second * 1000;

					if (this.time / 1000 === 59) {
						this.$toast(this.$t("reservation.prize_succ") + this.lottery.now_expect);
					}
					this.shanzi_1 = "img/lottery/shaizi/" + res.data?.opencode[0] + ".png";
					this.shanzi_2 = "img/lottery/shaizi/" + res.data?.opencode[1] + ".png";
					this.shanzi_3 = "img/lottery/shaizi/" + res.data?.opencode[2] + ".png";
					this.sum = res.data.opencode[0] + res.data.opencode[1] + res.data.opencode[2];
					if (this.sum >= 11 && this.sum <= 18) {
						this.size = '1번';
					} else if (this.sum >= 3 && this.sum <= 10) {
						this.size = '2번';
					}
					if (this.sum % 2 === 0) {
						this.double = '4번';
					} else {
						this.double = '3번';
					}
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			})

		}
	},
	created() {
		if (!localStorage.getItem('token')) {
			this.$router.push({ path: '/Login' })
		} else {
			this.getUserInfo();
			this.getLotteryInfo();
			this.getLotteryList();
			this.getBasicConfig();
		}
	},
	destroyed() {
		clearInterval(time);
	}
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.page{
	background: #f4f4f4;
}

::v-deep .van-notice-bar__left-icon,
.van-notice-bar__right-icon {
	min-width: 40px;
	font-size: 40px;
}
.notice-swipe {
	background: #2c2b29;
	width: 100%;
	padding: 0 5%;
	height: 85px;
	background: #2c2b29;
	font-size: 30px;
}
.nav-bar .right {
	padding-left: 8px;
	padding-right: 8px;
	color: #fff;
	font-size: 26px;
	line-height: 40px;
}

.record {
	z-index: 1;
}

.record .period {
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding:20px 0 30px;
	box-shadow: 0 2px 2px 0 #dfdfdf;
}

.record .period .cover {
	width: 60px;
	height: 60px;
	-o-object-fit: cover;
	object-fit: cover;
}

.record .period .period-number {
	flex: 1;
	text-align: center;
	span{
		font-size: 24px;
		color: #000;
	}
	p{
		margin: 10px 0 0;
		font-size: 28px;
	}
}

.van-count-down {
	color: #ff253f;
	font-size: 45px;
	margin-top: 10px;
	line-height: 45px;
}

.linear-gradient {
	width: 100%;
	height: 2px;
}

.record .recent {
	height: 30px;
	margin: -5px auto 0;
	background: #fff;
	text-align: center;
	.van-icon{
		background: #f4f4f4;
		width:80px;
		font-size: 30px;
	box-shadow: 1px 1px 3px #ccc;
	}
}

.kuaisan-ball .left {
	justify-content: flex-start;
}

.kuaisan-ball {
	flex: 1;
	display: flex;
	align-items: center;
}

.kuaisan-ball .res-img {
	width: 70px;
	height: 70px;
	margin-right: 30px;
}

.kuaisan-ball .res-des {
	font-weight: 700;
	text-align: center;
	color: #000;
}

.kuaisan-ball .res-des.middle {
	width: 15%;
	font-size: 35px;
}

.van-icon {
	font-size: 40px;
}

.down {
	transition: all .5s;
}

.up {
	transform: rotate(180deg);
	transition: all .5s;
}

.wrapper {
	position: relative;
	flex: 1;
	overflow: hidden;
}

.options-bar {
	display: flex;
}

.options-bar .game {
	flex: 1;
	height: 100%;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	background: #fff;
	padding: 10px;
	.tit{
		text-align:center;
		background: #f4f4f4;
		line-height: 60px;
	}
}

.options-bar .game .tips {
	display: flex;
	align-items: center;
	height: 100px;
	padding: 0 20px;
}

.options-bar .game .tips .odds {
	flex: 1;
	font-size: 35px;
	font-weight: 500;
	color: #ff253f;
}

.options-bar .game .tips .play-tip {
	display: flex;
	align-items: center;
	height: 100%;
}

::v-deep .van-icon-more-o {
	color: #ff253f;
	font-size: 50px;
}

.options-bar .game .tips .play-tip .span-text {
	margin-left: 10px;
	font-size: 35px;
	font-weight: bolder;
	color: #ff253f;
}

.linear-gradient {
	width: 100%;
	height: 2px;
}

.sumValueTwoSides {
	display: flex;
	padding: 10px 0;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	border: solid 1px #efefef;
	padding:5px;
}

.rectangle {
	overflow: hidden;
}

.rectangle.large {
	width: 25%;
	border-radius: 10px;
	padding:0px 5px 20px;
}

.rectangle .wrapper {
	position: relative;
}

.rectangle .wrapper .content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	border:solid 1px #ccc;
}

.rectangle .wrapper .content .name-text.large {
	font-size: 28px;
	margin: 15px 0;
	color: #f00;
	font-weight: bold;
}

.rectangle .wrapper .content .name-text {
	color: #7d7c7c;
	font-weight: bolder;
}

.rectangle .wrapper .content .odd-text.large {
	font-size: 25px;
	margin-top: -30px;
}

.rectangle .wrapper .content .odd-text {
	text-align: center;
	color: #ff253f;
}

.bottom-bar {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 130px;
	z-index: 2;
	background-color: #333;
}

.bottom-bar .bar .left{
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 30px 15px;
	padding: 5px 20px;
	border: solid 1px #ccc;
	border-radius: 10px;
}

.bottom-bar .bar {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.bottom-bar .bar .left .item {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 20px;
}

.bottom-bar .bar .left .item .text {
	font-size: 28px;
	color: #fff;
}

.jixuanico {
	font-size: 45px;
}

.bottom-bar .bar .left .line {
	width: 2px;
	height: 50px;
	background: #dadada;
}

.bottom-bar .bar .mid {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	color:#fff;
}
.bottom-bar .bar .mid .number {
	color:#f00;
	margin: 0 15px;
}

.bottom-bar .bar .mid .text {
	font-size: 30px;
	font-weight: 500;
	color: #000;
}

.bottom-bar .bar .mid .text.num {
	margin: 0 5px;
	color: #ff253f;
}

.bottom-bar .bar .right {
	padding: 0 30px;
	color: #fff;
	background: #e54042;
	font-size: 40px;
	font-weight: 500;
	height: 130px;
	line-height: 130px;
}

.rectangle.active .wrapper {
	background-color: #ff253f !important;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
	color: #000000;
	font-size: 35px;
}

::v-deep .van-popup {
	position: absolute;
}

::v-deep .van-overlay {
	position: absolute;
	background-color: rgb(70 67 67 / 70%);
}

::v-deep .van-popup--top {
	top: -1px;
}

.wrapper .item {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 0;
}

.wrapper .item .left {
	width: 40%;
	font-size: 30px;
	text-align: center;
	font-weight: 500;
	color: #000;
}

.font-weight {
	font-weight: 700 !important;
}

.wrapper .item .right {
	flex: 1;
	display: flex;
	font-size: 30px;
	justify-content: center;
	overflow: hidden;
	color: #000;
}

.wrapper .item .kuaisan-ball .left {
	justify-content: flex-start;
}

.wrapper .item .kuaisan-ball {
	margin-left: 20px;
	flex: 1;
	display: flex;
	align-items: center;
}

.wrapper .item .kuaisan-ball .res-img {
	width: 50px;
	height: 50px;
	margin-right: 20px;
}

.wrapper .item .kuaisan-ball .res-des {
	font-weight: 700;
	text-align: center;
	color: #000;
}

.wrapper .item .kuaisan-ball .res-des.middle {
	width: 15%;
	font-size: 35px;
}

.play-type-tip {
	position: unset;
	margin: auto;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 650px;
	height: 700px;
	max-height: 50%;
	z-index: 10;
	border-radius: 30px;
	overflow: hidden;
	background-color: #fff;
	color: #000;
}

.play-type-tip .title {
	line-height: 90px;
	background: linear-gradient(90deg, #7e5678, #e6c3a1);
	text-align: center;
	color: #fff;
	font-size: 35px;
	font-weight: 500;
}

.mask {
	background-color: rgb(0 0 0 / 0%);
	animation-duration: 0.35s;
}

.play-type-tip .wrapper {
	height: calc(100% - 10px);
	background-color: transparent;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.play-type-tip .wrapper .item {
	padding: 40px 50px;
	display: flex;
	align-items: flex-start;
}

.play-type-tip .wrapper .item .van-icon {
	color: #e6c3a1;
	font-size: 60px;
}

.play-type-tip .wrapper .item .content .content-title {
	margin-top: 22px;
	font-size: 35px;
	font-weight: 500;
	color: #000;
	line-height: 0px;
}

.play-type-tip .wrapper .item .content .content-detail {
	margin-top: 5px;
	font-size: 22px;
	color: #000;
	line-height: 30px;
}

.play-type-tip .wrapper .item .content {
	flex: 1;
	margin-left: 30px;
}

.rectangle.active .wrapper {
	background-color: #ff253f !important;
}

.rectangle.active .wrapper .name-text,
.rectangle.active .wrapper .odd-text {
	color: #fff !important;
}

.bottom-bar .inputbox {
	position: absolute;
	top: 0px;
	left: 0;
	right: 0;
	padding: 10px 20px;
	background-color: #fff;
	z-index: 1;
	box-shadow: 0 0 10px 0 #cacaca;
	transition: transform .3s cubic-bezier(.21, 1.02, .55, 1.01);
}

.bottom-bar .inputbox.active {
	transform: translateY(-100%);
}

.bottom-bar .inputbox .item {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 65px;
}


.bottom-bar .inputbox .item .bet-number {
	flex: 1;
	margin: 0 16px;
	overflow: auto;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
	color: #ff253f;
	font-size: 30px;
	font-weight: 500;
	height: 40px;
	line-height: 40px;
}

.bottom-bar .inputbox .amount-wrapper {
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.bottom-bar .inputbox .amount-wrapper .van-cell {
	font-size: 30px;
	line-height: 35px;
	width:100px;
	border:solid 1px #ccc;
	color: #fff;
}
.bottom-bar .inputbox .amount-wrapper .label {
	font-size: 30px;
	line-height: 30px;
	color: #000;
	border:solid 1px #f00;
	padding:12px 20px;
	margin-left: 15px;
	border-radius: 10px;
}

.bottom-bar .inputbox .amount-wrapper .money{
	flex: 1;
	text-align: right;
	.num{
		font-size: 30px;
		color: #f00;
	}
}

.bottom-bar .inputbox .item .part {
	margin-right: 20px;
}

.bottom-bar .inputbox .item .part span {
	font-size: 30px;
	vertical-align: center;
	color: #000;
}

.bottom-bar .inputbox .item .part .number {
	margin: 0 5px;
	color: #ff253f;
	font-weight: 500;
}

::v-deep .van-field__control {
	color: #ff253f;
}

.confirm-order-modal {
	position: unset;
	display: flex;
	flex-direction: column;
	margin: auto;
	padding: 0 20px 30px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 610px;
	height: 680px;
	max-height: 50%;
	z-index: 10;
	background-color: #fff;
	border-radius: 10px;
}

.confirm-order-modal .head {
	position: relative;
	height: 80px;
}

.confirm-order-modal .head .text {
	padding: 0 20px;
	height: 30px;
	line-height: 10px;
	text-align: center;
	font-size: 35px;
	font-weight: 500;
	color: #7e5678;
}

::v-deep .confirm-order-modal .van-hairline--bottom::after {
	border-bottom-width: 2px;
}

.van-popup--center {
	border-radius: 15px;
}

.confirm-order-modal .list {
	flex: 1;
	padding: 0 10px;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.confirm-order-modal .list .lise-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 0;
}

.confirm-order-modal .list .lise-item .main {
	flex: 1;
	overflow: hidden;
}

.confirm-order-modal .list .lise-item .main .bet-name {
	color: #ff253f;
	font-size: 35px;
	font-weight: 500;
	line-height: 0px;
	word-wrap: break-word;
	word-break: break-all;
}

.confirm-order-modal .list .lise-item .main .detail-text {
	line-height: 0px;
	font-size: 25px;
	color: #979799;
}

.confirm-order-modal .list .lise-item {
	color: #ff253f;
}

.confirm-order-modal .sub-bar {
	display: flex;
	align-items: center;
	margin-top: 30px;
	justify-content: space-around;
}

.confirm-order-modal .sub-bar .item {
	min-width: 40%;
	height: 80px;
	text-align: center;
	box-sizing: border-box;
	font-size: 35px;
	font-weight: 500;
}

.confirm-order-modal .sub-bar .item.cancel-btn {
	border: 2px solid #979799;
	color: #979799;
	background-color: #fff;
}

.confirm-order-modal .sub-bar .item.sub-btn {
	background: #f00;
	color: #fff;
	border: 0;
}
.next-number{
	text-align: center;
	width:50%;
}
.next-number span {
	font-size: 24px;
	color: #000;
}
</style>
