<template>
	<div class="page">
		<van-nav-bar :title="$t('concubine.concubine')" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="box">
			<div class="fenmian">
				<img :src="xuanfeidata.image" />
				<div class="xf_title"></div>
			</div>
			<div class="xf_basetit">
				<p class="name">V{{ this.xuanfeidata.id + 100 }}</p>
				<p class="title">{{ this.xuanfeidata.xuanfei_name }}</p>
			</div>
			<div class="xftitle">{{ $t('xuanfei.xfpage_title1') }}</div>
			<div class="xf_liuc">
				<div>
					<img src="../images/item/ico_xf_i1.png" />
					<p>{{ $t('xuanfei.xf_liuc_tit1') }}</p>
				</div>
				<div>
					<img src="../images/item/ico_xf_i2.png" />
					<p>{{ $t('xuanfei.xf_liuc_tit2') }}</p>
				</div>
				<div>
					<img src="../images/item/ico_xf_i3.png" />
					<p>{{ $t('xuanfei.xf_liuc_tit3') }}</p>
				</div>
				<div>
					<img src="../images/item/ico_xf_i4.png" />
					<p>{{ $t('xuanfei.xf_liuc_tit4') }}</p>
				</div>
			</div>
			
			<div class="xftitle">{{ $t('xuanfei.xfpage_title2') }}</div>
			<div class="xf_base">
				<div>
					<span>{{ this.xuanfeidata.xuanfei_type }}</span>
					<p>{{ $t('xuanfei.xf_info_age') }}</p>
				</div>
				<div>
					<span>{{ this.xuanfeidata.xuanfei_info }}</span>
					<p>{{ $t('xuanfei.xf_info_sgao') }}</p>
				</div>
				<div class="end">
					<span>{{ this.xuanfeidata.xuanfei_sign }}</span>
					<p>{{ $t('xuanfei.xf_info_tzong') }}</p>
				</div>
				<div>
					<span>{{ this.xuanfeidata.xuanfei_tel }}</span>
					<p>{{ $t('xuanfei.xf_info_zaob') }}</p>
				</div>
				<div>
					<span>{{ this.xuanfeidata.name }}</span>
					<p>{{ $t('xuanfei.xf_info_diqu') }}</p>
				</div>
				<div class="end">
					<span>{{ this.xuanfeidata.xuanfei_fen }}</span>
					<p>{{ $t('xuanfei.xf_info_fwquyu') }}</p>
				</div>
				<div class="line">
					<span>{{ this.xuanfeidata.xuanfei_fuwu }}</span>
					<p>{{ $t('xuanfei.xf_info_fuwu') }}</p>
				</div>
			</div>

			<div class="xftitle">{{ $t('xuanfei.xfpage_title3') }}</div>
			<div class="xf_detail">
				<van-image width="50%" fit="contain" height="100%" v-for="(v, k) in xuanfeidata.img_url" :key="k" :src="v" />
			</div>
			<div class="xftitle">{{ $t('xuanfei.xfpage_title4') }}</div>
			<van-button @click="active=true" class="button">{{$t("xuanfei.xiadang")}}</van-button>
		</div>
		<van-popup v-model="active" position="top" :style="{ height: '100%' }">
			<div class="orderbox">
				<div class="item">
					<van-icon name="arrow-left" color="#fff" @click="active=false" />
					<div class="title">{{ $t("xuanfei.xfpage_title8") }}</div>
				</div>
				<div class="xftitle">{{ $t('xuanfei.xfpage_title5') }}</div>
				<div class="reninfo">
					<div class="renimg"><img :src="xuanfeidata.image" /></div>
					<div class="nameinfo">
						<p><span><i></i>{{ $t('xuanfei.renzheng') }}</span>{{ xuanfeidata.id+100 }}</p>
						<p>{{ xuanfeidata.xuanfei_name }}</p>
					</div>
				</div>
				<div class="xftitle">{{ $t('xuanfei.xfpage_title6') }}</div>
				<div class="con1" style="border-bottom: solid 1px #ccc;">{{ $t('xuanfei.content1') }}</div>
				<div class="xftitle">{{ $t('xuanfei.xfpage_title7') }}</div>
				<div class="con1">{{ $t('xuanfei.content2') }}</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
export default {
	data() {
		return {
			xuanfeidata: [],
			active:false
		};
	},
	methods: {
		back() {
			this.$router.push({ path: 'list?id=' + this.$route.query.adsid + '&name=' + this.$route.query.name });
		},
		getxuanfeidata() {
			this.$http({
				method: 'get',
				url: 'xuanfeidata',
				data: { id: this.$route.query.id }
			}).then(res => {
				this.xuanfeidata = res.data;
			});
		},
		yuyue() {
			this.$toast(this.$t("reservation.counselor"));
		}
	},
	created() {
		this.getxuanfeidata();
	}
};
</script>

<style lang='less' scoped>
.container {
	display: inline-block;
}
.page .nav-bar{
	background: none;
	position: fixed;
	width: 100%;
}
.box {
	width: 100%;
	margin: 0 auto;
	text-align: center;
	padding-bottom: 6.25rem;
}
.fenmian{
	width: 100%;
	height: 800px;
	overflow: hidden;
	position: relative;
	img{
		width:100%
	}
	.xf_title{
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 90px;
		border-radius: 10px;
		background: url(../images/item/bg_xf_title.png) no-repeat 20px center #fbc893;
		background-size: 160px;
	}
}
.xftitle{
	font-size: 26px;
    font-weight: 500;
    color: #ECCA6F;
    padding: 0 2.5rem;
	background: url(../images/item/ico_tit.png) no-repeat;
    background-size: 30px 30px;
    background-position: 0.9375rem center;
	margin: 36px 0;
	text-align: left;
}
.xf_basetit{
	color: #fff;
	text-align: left;
	line-height: 50px;
	padding: 0 30px;
	p{
		margin: 0;
	}
	
	.name {
		font-size: 28px;
		font-weight: bold;
	}
	.title {
		font-size: 24px;
	}
}
.xf_liuc{
	display: flex;
	justify-content: space-around;
	div{
		display: flex;
		flex-direction: column;
		align-items: center;
		img{
			width: 50%;
		}
		p{
			margin: 10px 0;
			color:#ECCA6F;
			font-size: 24px;
		}
	}
}
.xf_base{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	div{
		width: 33%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding-left: 30px;
		margin-bottom: 20px;
		span{
			color: #EFD6A6;
		}
		p{
			color: #CCCCCC;
			font-size: 24px;
		}
	}
	.end{
		padding-left: 50px;
	}
	.line{
		padding: 20px 30px 20px;
		align-items: flex-start;
		width: 100%;
		border-top: solid 1px #ccc;
		border-bottom: solid 1px #ccc;
	}
}
.xf_detail{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.van-image{
		width: 50%;
		padding: 10px;
		img{
			width: 100%;
		}
	}
}
.button {
	width: 80%;
	height: 2.5rem;
	font-size: 0.9375rem;
	margin-top: 0.625rem;
	background: #ceb585;
	color: #000000;
}
.van-popup{
	background: #1b1b1b;
}
.orderbox{
	padding: 0 30px;
	color: #fff;
	background: url(../images/item/bg_xf_foot.png) no-repeat center bottom;
	background-size: 80%;
	padding-bottom: 300px;
	.item{
		height: 80px;
		position: relative;
		.van-icon{
			position: absolute;
			left: 10px;
			top: 20px;
		}
		.title{
			line-height: 80px;
			color: #fff;
			text-align: center;
			font-size: 40px;
		}
	}
	.reninfo{
		display: flex;
		justify-content: flex-start;
		padding-bottom: 30px;
		border-bottom: solid 1px #ccc;
		.renimg{
			width: 120px;
			height:120px;
			overflow: hidden;
			margin-right: 30px;
			margin-left: 5px;
				border-radius: 20px;
				border: 1px solid #ebca70;
			img{
				width: 100%;
			}
		}
		.nameinfo{
			flex: 1;
			p{
				margin: 0;
				font-size: 24px;
				padding: 10px 0;
				span{
					display: block;
					background: linear-gradient(#C7AE96, #F7E8D1);
					color: #A37458;
					font-size: 28px;
					padding: 0px 20px 0 10px;
					margin-right: 20px;
					border-top-left-radius: 10px;
					border-bottom-right-radius: 10px;
					i{
						background: url(../images/item/ico_rz.png) no-repeat center center;
						background-size: 20px 20px;
						padding: 20px;
					}
				}
			}
			p:first-child{
				display: flex;
			}
		}
	}
	.con1{
		padding-bottom: 30px;
	}
}
</style>
