<template>
	<div class="container page">
		<van-nav-bar :title="$t('setting.bank_info')" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="wrapper">
			<div class="add-card" @click="toBindCard()" v-if="!is_bind">
				<van-icon name="plus" />
				<span>{{ $t("setting.add_bank") }}</span>
			</div>
			<div class="bank" v-else>
				<div class="info">
					<div class="row-content">{{ this.bankInfo.bankinfo }}</div>
					<div class="row-content">{{ this.userInfo.name }}</div>
					<div class="row-content">{{ this.bankInfo.bankid }}</div>
				</div>
			</div>
			<div class="tips">{{ $t("setting.bind_bank_tip") }}</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			is_bind: false,
			bankInfo: {},
			userInfo: {}
		};
	},
	methods: {
		back() {
			return window.history.back();
		},
		getUserBankInfo() {
			this.$http({
				method: 'get',
				url: 'user_get_bank'
			}).then(res => {
				if (res.code === 200) {
					if (res.data.is_bank) {
						this.is_bind = true;
						this.bankInfo = res.data.info;
					} else {
						this.is_bind = false;
					}
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			})
		},
		getUserInfo() {
			this.$http({
				method: 'get',
				url: 'user_info'
			}).then(res => {
				if (res.code === 200) {
					this.userInfo = res.data;
					this.name = res.data.name;
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			})
		},
		toBindCard() {
			if (!this.userInfo.name) {
				this.$router.push("Setname");
				this.$toast(this.$t("setting.set_name_bank"));
				return true;
			} else if (!this.userInfo.paypassword) {
				this.$router.push("SetPayPassword");
				this.$toast(this.$t("setting.set_pwd_bank"));
				return true;
			} else {
				this.$router.push({ path: '/BindCard' })
			}
		}
	},
	created() {
		if (!localStorage.getItem('token')) {
			this.$router.push({ path: '/Login' })
		} else {
			this.getUserInfo();
			this.getUserBankInfo();
		}
	}
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.page {
	background: #f4f4f4;
}

.van-cell {
	font-size: 35px;
	line-height: 80px;
}

.container p {
	padding: 0 15px;
	margin-top: 15px;
	font-size: 30px;
	color: #dc2037;
}

.wrapper {
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	margin-top: 30px;
	background: #fff;
}

.wrapper .add-card {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #000;
	background-color: #fff;
	height: 250px;
}

.wrapper .add-card span {
	margin-left: 10px;
	font-size: 30px;
}

.wrapper .tips {
	margin: 15px 15px;
	font-size: 25px;
	color: #979799;
}

.wrapper .bank .info {
	margin-left: 20px;
	flex: 1;
	color: #000;
}

.wrapper .bank .info .row-content {
	margin: 30px 0;
	line-height: 20px;
	font-size: 30px;
}
</style>
