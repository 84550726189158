<template>
	<div class="bg-container page">
		<div class="bg-wrapper">
			<div class="login">
				<van-nav-bar class="nav-bar">
					<template #left>
						<van-icon name="arrow-left" color="#fff" @click="back()" />
					</template>
				</van-nav-bar>
				<div class="wrapper">
					<!-- <div class="title">
						{{ $t('auth.logintit') }}
						<div class="des">{{ $t('auth.agreement_place') }}</div>
					</div> -->
					<div class="loginForm">
						<van-field v-model="username" clearable class="input"
							:placeholder="$t('auth.username_place')" />
						<van-field v-model="password" :type="passwordType" class="input"
							:placeholder="$t('auth.pwd_place')">
							<template slot="right-icon">
								<van-icon :name="passwordType === 'password' ? 'closed-eye' : 'eye-o'"
									@click="switchPasswordType" />
							</template>
						</van-field>
						<div class="agreement" style="display: flex;">
							<van-checkbox v-model="checked" shape="square" > {{$t("auth.rememberpwd")}}</van-checkbox>
							
						</div>
						<van-button class="login-btn" type="primary" size="normal" @click="doLogin()">{{ $t("auth.login") }}</van-button>
						
						<div @click="toRegister()" class="register-text">{{ $t("auth.register") }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	model: {
		prop: "inputValue",
		event: "input",
	},
	props: {
		/**
		 * 当前输入的值
		 */
		inputValue: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			username: "",
			lang: 'en_us',
			password: this.inputValue,
			passwordType: "password",
			checked:false
		};
	},
	mounted() {
		// localStorage.setItem("lang", 'ms_my');
		this.lang = 'ms_my';
	},
	methods: {
		switchPasswordType() {
			this.passwordType =
				this.passwordType === "password" ? "text" : "password";
		},
		back() {
			return window.history.back();
		},
		toRegister() {
			this.$router.push("Register");
		},
		doLogin() {
			if (
				this.username === "" ||
				this.username === null ||
				this.username === undefined
			) {
				this.$toast(this.$t("auth.username_place"));
				return false;
			}
			if (
				this.password === "" ||
				this.password === null ||
				this.password === undefined
			) {
				this.$toast(this.$t("auth.pwd_place"));
				return false;
			}
			this.$http({
				url: "member_login",
				method: "post",
				data: {
					username: this.username,
					password: this.password,
					lang: this.lang
				},
			}).then((res) => {
				if (res.code === 200) {
					this.$toast.success(res.msg);
					localStorage.setItem("token", res.data.id);
					localStorage.setItem("uid", res.data.id);
					localStorage.setItem("usname", res.data.username);
					this.$router.push("Mine");
				} else {
					this.$toast('네트워크에 이상이 있습니다. 다시 로그인해 주세요');
				}
			});
		},
	},
	created() {
		if (localStorage.getItem("token")) {
			return window.history.back();
		}
	},
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.login {
	height: 100%;
}
.bg-container{
	
	background: url(../images/logo4.png) no-repeat left top;
	background-size: 100% 100%;
}
.bg-container .bg-wrapper .login .nav-bar {
	background: 0 0;
}

.language {
	position: absolute;
	top: 4px;
	right: 0;
	height: 80px;

	img {
		height: 100%;
	}
}

.login .wrapper {
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.logo-container {
	margin: 0 auto;
	width: 45%;
}

.logo-container .logo-wrapper {
	position: relative;
	padding-bottom: 62.5%;
}

.logo-container .logo-wrapper .logo-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-o-object-fit: contain;
	object-fit: contain;
}

.login .wrapper .title {
	line-height: 100px;
	font-size: 45px;
	color: #fff;
	letter-spacing: 5px;
	padding: 60px;
	.des{
		font-size: 26px;
	}
}

.login .wrapper .loginForm {
	padding: 220px 60px 60px;
}

.login .wrapper .loginForm .input {
	padding: 10px 20px;
	margin-top: 40px;
	border-radius: 10px;
	text-align: left;
	line-height: 70px;
	font-size: 30px;
	color: #fff;
	background: #0f0f0f;
}
.agreement{
	margin-top: 40px;
	height: 40px;
	color: #fff;
}

::v-deep .van-checkbox__icon .van-icon{
	font-size: 20px;
	border:solid 1px #007aff;
}
::v-deep .van-checkbox__label{
	color: #fff;
}

::v-deep .van-field__right-icon .van-icon {
	font-size: 50px;
	width: 50px;
	height: 50px;
}

::v-deep .van-icon {
	font-size: 50px;
}

::v-deep .van-field__control {
	color:#fff;
}

.login .wrapper .loginForm .reset-text {
	margin: 30px 15px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.login .wrapper .loginForm .reset-text span {
	color: #fff;
	font-size: 25px;
	font-weight: 500;
	line-height: 15px;
}

.login .wrapper .loginForm .register-text {
	margin-top: 30px;
	width: 100%;
	height: 100px;
	line-height: 100px; 
	text-align: center;
	border-radius: 10px;
	color: #fff;
	background: #aea9a5cc;
	font-size: 30px;
	border: none;
}

.login .wrapper .loginForm .login-btn {
	margin-top: 85px;
	width: 100%;
	height: 100px;
	border-radius: 10px;
	color: #fff;
	background: #6b6968cc;
	font-size: 30px;
	border: none;
}

</style>
