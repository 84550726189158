<template>
	<div class="home-container">
		<div class="linear-bg">
			<!-- <div class="logo">
				<img class="logo-img" :src="this.$store.getters.getBaseInfo.ico !== undefined ? this.$store.getters.getBaseInfo.ico : ''">
			</div>	 -->
			<div class="pagetitle">
				<ul>
					<li class="sel" @click="gotoMenu('/Home')">{{ $t('index.tuij') }}</li>
					<li @click="gotoMenu('/Video')">{{ $t('index.video') }}</li>
				</ul>
			</div>
		</div>
		<div class="home-scroll">
			<div class="banner">
				<swiper class="banner_swiper" :options="bannerSwiperOption">
					<swiper-slide v-for="(v, key) in banners" :key="key">
						<van-image class="banner_img" round :src="v.url">
							<template v-slot:loading>
								<van-loading type="circular" />
							</template>
						</van-image>
					</swiper-slide>
				</swiper>
			</div>
			<div class="notice-bar">
				<van-notice-bar class="notice-swipe" left-icon="bullhorn-o" color="#FFCC99" :text="this.notice" />
				<div class="linear-gradient"></div>
			</div>
			<div class="hot-game">
				<div class="hot-title-div" v-if="usname!=''">
					<div>
						<span>{{ $t('index.hi') }}</span>
						<p>{{ usname }}</p>
					</div>
				</div>
				<div class="hot-items-div">
					<van-grid :border=false :column-num="2" :icon-size="20">
						<van-grid-item @click="toLottery(v.key, v.id)" v-for="(v, key) in gameitem" :key="key">
							<span>{{ v.name }}</span>
							<van-image class="game_item_img" :src="v.ico">
								<template v-slot:loading>
									<van-loading type="circular" />
								</template>
							</van-image>
						</van-grid-item>
					</van-grid>
				</div>
			</div>
			<div class="hot-recommend" v-for="(item, ind) in movielist_1" :key="ind">
				<div class="hot-title-div2">
					<div>
						<span>{{ $t('index.'+item.title) }}</span>
					</div>
				</div>
				<div class="movie_list_1">
					<div class="movie-list-item" v-for="(v, key) in item.list" :key="key"
						@click="toPlayVideo(v.id)">
						<van-image class="cover_img" round :src="v.cover">
							<template v-slot:loading>
								<van-loading type="circular" />
							</template>
						</van-image>
						<!-- <div class="movie-list-item-bottom">
							<div class="movie-time-div"> -->
								<!-- <span style="width:100%;padding-right: 0;">{{ v.vod_name }}</span> -->
								<!-- <span>{{$t('video.play')}}:{{v.count}}</span> -->
							<!-- </div>
						</div> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			notice: this.$t("index.loading"),
			banners: [{}],
			basicData: [],
			gameitem: [{}, {}, {}, {}],
			movielist_0: [{}, {}, {}, {}],
			movielist_1: [{}, {}, {}, {}, {}, {}, {}, {}],
			isLoading: false,
			usname:'',
			movielistSwiperOption: {
				slidesPerView: 'auto',
				spaceBetween: 0,
				slidesPerGroup: 1,
			},
			bannerSwiperOption: {
				effect: 'coverflow',
				grabCursor: true,
				centeredSlides: true,
				slidesPerView: 'auto',
				speed: 800,
				autoplay: true,
				coverflowEffect: {
					rotate: 50,
					stretch: 10,
					depth: 100,
					modifier: 1,
					slideShadows: true
				}
			}
		};
	},
	methods: {
		gotoMenu(router) {
			this.$router.replace(router)
		},
		toLottery(key, id) {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' })
			} else {
				this.$router.push({ path: '/Lottery?key=' + key + "&id=" + id })
			}

		},
		toPlayVideo(id) {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' })
			} else {
				this.$router.push({ path: '/PlayVideo?id=' + id })
			}
		},
		onRefresh() {
			setTimeout(() => {
				this.getBasicConfig();
				this.isLoading = false;
				this.$toast("刷新成功");
			}, 500);
		},
		getBasicConfig() {
			this.$http({
				method: 'get',
				url: 'sys_config'
			}).then(res => {
				this.basicData = res.data;
				this.getNotice(this.basicData);//获取公告
				this.getBanner(this.basicData);//获取banner
				this.getGameItem();//获取首页游戏列表
				this.getMovieList_0(this.basicData);//获取首页视频0
				this.getMovieList_1(this.basicData);//获取首页视频1
			})

		},
		getNotice(data) {
			this.notice = data.notice;
		},
		getGameItem() {
			this.$http({
				method: 'get',
				url: 'lottery_hot'
			}).then(res => {
				this.gameitem = res.data
			})
		},
		getMovieList_0(data) {
			this.movielist_0 = data.movielist_0
			//console.log(this.movielist_0);
		},
		getMovieList_1(data) {
			this.movielist_1 = data.movielist_1
		},
		getBanner(data) {
			this.banners = data.banners;
		}
	},
	mounted() {

	},
	created() {
		this.getBasicConfig();
		if(localStorage.getItem("usname")){
			this.usname = localStorage.getItem("usname");
		}
	}
}

</script>

<style lang='less' scoped>
@notice-bar-size: 30px;
@movie-list-item-bottom-size: 25px;

.linear-bg {
	position: relative;
    padding: .1875rem 0;
    box-sizing: border-box;
	background: #2c2b29;
	.logo{
		height: 2.5rem;
		position: absolute;
		top: 10px;
		left: 10px;
		width: 30%;
		text-align: center;
		padding-top: 5px;
		img{
			height: 90%;
		}
	}
	.pagetitle{
		margin: 0 5.5rem;
		height: 80px;
		width: auto;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		ul{
			height: 1.625rem;
			line-height: 1.625rem;
			border-top: 0.0625rem solid #eacc9b;
			border-bottom: 0.0625rem solid #eacc9b;
			border-radius: 0.875rem;
			width: 7.375rem;
			margin: 0.375rem auto;
			display: flex;
			justify-content: space-evenly;
			li{
				font-size: 0.8125rem;
				font-family: PingFang SC;
				font-weight: 500;
				color: #FFCC99;
				flex: 1;
				text-align: center;
			}
			li:first-child{
				border-left: 1px solid #eacc9b;
				border-top-left-radius: 0.8125rem;
				border-bottom-left-radius: 0.8125rem;
			}
			.sel{
				color: #855A2E;
				background: #FFCC99;
			}
		}
	}
}

.home-container {
	background: #1b1b1b;
}

.linear-gradient {
	width: 100%;
	height: 2px;
	background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
}

::v-deep .van-notice-bar__left-icon,
.van-notice-bar__right-icon {
	min-width: 40px;
}

.notice-swipe {
	background: #2c2b29;
	width: 100%;
	padding: 0 5%;
	height: 85px;
	background: #2c2b29;
	font-size: @notice-bar-size;
}

::v-deep .van-icon-bullhorn-o::before {
	transform: scale(2.5);
}

.banner {
	width: 100%;
    padding: 0 5%;
    border-radius: 10px;
    overflow: hidden;
	background: #2c2b29;
}

.banner_swiper {
	height: 100%;
	width: 100%;

	.swiper-slide {
		border-radius: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 300px;
		text-align: center;
		font-weight: bold;
		font-size: 20px;
		background-color: #ffffff;
		background-position: center;
		background-size: cover;
		color: #ffffff;
	}
}

::v-deep .swiper-container-3d .swiper-slide-shadow-left {
	background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

::v-deep .swiper-container-3d .swiper-slide-shadow-right {
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

.banner_img {
	border-radius: 10px;
	width: 100%;
	height: 100%;
}

.hot-game {
	width: 100%;
	height: 100%;
}

.hot-title-div {
	width: calc(100% - 50px);
	margin: 0 auto;
	padding-top: 30px;	
	display: flex;
	color: #fff;
	align-items: center;
	justify-content: space-between;
}

.hot-title-div>div:first-child {
	width: 430px;
}

.hot-title-div>div:first-child span {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 28px;
	color: #FFCC99;
}

.hot-title-div>div:first-child p {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 28px;
	color: #FFCC99;
}

.hot-title-div>div:nth-child(2) span {
	font-size: 20px;
	color: #979799;
}

.hot-title-div>div:nth-child(2) span {
	font-size: 25px;
	color: #979799;
}

.hot-game .hot-items-div {
	padding: 0 15px 30px;
}

.hot-game .hot-items-div span {
	font-size: 24px;
	color: #FFDAB5;
}

.hot-recommend {
	width: 100%;
	flex: 1;
}
.hot-title-div2 {
	background: url(../images/item/bg_title.png) no-repeat 25px center;
	background-size: 170px 60px;
	height: 1.8125rem;
    line-height: 1.5625rem;
    font-size: 0.75rem;
    font-weight: bold;
    color: #8C6135;
    min-width: 4.125rem;
	margin-bottom:30px;
	div{
		padding-left: 50px;
	}
}

.movie_swiper {
	.swiper-slide {
		width: 80%;
	}
}

.movie_list_0 {
	width: calc(100% - 50px);
	margin: 0 auto;
}

.movie_cover {
	border-radius: 10px;
	width: 550px;
	height: 330px
}

.movie_list_0 .movie-list-item-bottom {
	position: relative;
	width: 550px;
	bottom: 43px;
}

.movie_list_0 .movie-list-item-bottom .movie-time-div {
	background-color: rgba(0, 0, 0, .4);
}

.movie_list_0 .movie-list-item-bottom>div {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
	font-size: 28px;
	color: #fff;
}

.movie_list_0 .movie-time-div {
	color: #fff;
	border-radius: 0 0 10px 10px;
}

.movie_list_0 .movie_swiper .hot {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 80px;
}

.movie_list_0 span {
	font-size: 30px;
}

.movie_list_1 {
	display: flex;
	width: calc(100% - 50px);
	margin: 0 auto;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
}

.movie_list_1 .movie-list-item .cover_img {
	border-radius: 10px;
	width: 335px;
	height: 200px;
}

.home-scroll {
	padding-bottom: 110px;
}

.movie_list_1 .movie-list-item {
	margin-bottom: 20px;
}

.movie_list_1 .movie-list-item-bottom {
	position: relative;
	width: 335px;
	bottom: 42px;

}

.movie_list_1 .movie-list-item:nth-child(odd) {
	margin-right: 25px;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div {
	background-color: rgba(0, 0, 0, .4);
}

.movie_list_1 .movie-list-item-bottom>div {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
	color: #fff;
}

.movie_list_1 .movie-time-div {
	color: #fff;
	border-radius: 0 0 10px 10px;
	height: 35px;
}

.movie_list_1 .movie_swiper .hot {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
	overflow: hidden;
	white-space: nowrap;
	width: 180px;
	padding-left: 8px;
	font-size: 25px;
}

.movie_list_1 .movie-list-item .movie-time-div span:last-child {
	overflow: hidden;
	white-space: nowrap;
	width: 0px;
	padding-right: 110px;
	font-size: 22px;
}

.movie_list_0 .movie-time-div span:first-child {
	overflow: hidden;
	white-space: nowrap;
	width: 350px;
	padding-left: 10px;
	font-size: 25px;
}

.hot-recommend-more {
	width: 100%;
	padding-bottom: 20px;
	text-align: center;
	color: #979799;
	font-size: 30px;
}

.hot-items-div .game_item_img {
	width: 100px;
	height: 100px;
}
::v-deep .van-grid-item{
	width: 100%;
}

::v-deep .van-grid-item__content--center {
	flex-direction: row;
	position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3.125rem;
    border: 1px solid #d0b59a;
    border-radius: 0.375rem;
    margin: 10px;
    color: #FFDAB5;
    font-size: 0.75rem;
    background: #2e2d2b;
    padding: 0 0 0 0.75rem;
    box-sizing: border-box;
	overflow: hidden;
}

::v-deep .hot-items-div .game_item_img .van-image__img {
	border-radius: 20px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
	color: #000000;
	font-size: 35px;
}
</style>
