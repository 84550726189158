<template>
	<div class="container page">
		<van-nav-bar :title="$t('concubine.concubine')" class="nav-bar">
			<template #left>
				<span>{{ vod_name }}</span>
				<van-icon name="arrow-down" color="#fff" @click="playgame=true" />
			</template>
		</van-nav-bar>
		<div class="right">
			<div class="xfbanner"><img src="../images/item/banner.png" /></div>
			<div class="xftitle">{{ $t('xuanfei.allxf') }}</div>
			<van-grid :column-num="1" :gutter="0">
					<!-- @click="profile(v.id)" -->
					<van-grid-item v-for="(v, k) in datalist" :key="k" class="game_item_img">
						<van-image :src="v.img_url">
							<template v-slot:loading>
								<van-loading type="circular" />
							</template>
						</van-image>
						<div class="rig-name">
							<p><span><i></i>{{ $t('xuanfei.renzheng') }}</span>{{ v.id+100 }}</p>
							<p>{{ v.xuanfei_name }}</p>
							<!-- <p class="btn">{{ $t('xuanfei.duiha') }}</p> -->
						</div>
					</van-grid-item>
				</van-grid>
		</div>
		<van-popup class="mask" position="top" get-container="body" v-model="playgame">
			<div class="play-type-tip">
				<div class="title">
					<van-icon name="arrow-left" class="titleft" color="#fff" @click="playgame=false" />
					{{ $t('xuanfei.addlist') }}
				</div>
				<div class="classlist">
					<ul>
						<li v-for="(item,ind) in classlist" :key="ind" @click="selclass(item)"><span>{{ item.name }}</span></li>
					</ul>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
export default {
	data() {
		return {
			vod_name: '',
			isLoading: false,
			playgame:false,
			datalist: [
				// {
				// 	xuanfei_name: '北京 健身达人',
				// 	img_url: 'https://kk.betman2.co/storage/41/62fe1bd26d01c_image_2022-08-18_190032731.png'
				// },
				// {
				// 	xuanfei_name: '北京 学生妹',
				// 	img_url: 'https://kk.betman2.co/storage/344/630869b4bd2d6_1.jpg'
				// }
			],
			classlist:[],
			classid:0
		};
	},
	methods: {
		selclass(v) {
			this.vod_name = v.name;
			this.classid = v.id;
			this.playgame = false;
			this.getxuanfeilist();
		},
		onRefresh() {
			setTimeout(() => {
				this.$toast(this.$t("reservation.refresh"));
				this.isLoading = false;
			}, 500);
		},
		profile(id) {
			this.$router.push({ path: '/profile?id=' + id + '&name=' + this.vod_name + '&adsid=' + this.$route.query.id });
		},
		getxuanfeilist() {
			this.$http({
				method: 'get',
				url: 'xuanfeilist',
				data: { id: this.classid }
			}).then(res => {
				console.log(res)
				this.datalist = res.data;
			});
		},
		getxuanfeiclass(){
			this.$http({
				method: 'get',
				url: 'address_list'
			}).then(res => {
				this.classlist = res.data;
			});
		}
	},
	created() {
		this.vod_name = this.$t('xuanfei.all');
		this.getxuanfeiclass();
		this.getxuanfeilist();
	}
};
</script>

<style lang='less' scoped>

.xfbanner{
	width: 100%;
	padding: 0 30px;
	img{
		width: 100%;
	}
}
.xftitle{
	font-size: 26px;
    font-weight: 500;
    color: #ECCA6F;
    padding: 0 2.5rem;
	background: url(../images/item/ico_tit.png) no-repeat;
    background-size: 30px 30px;
    background-position: 0.9375rem center;
	margin-top: 20px;
}
::v-deep .van-hairline--top::after{
	border: none;
}
::v-deep .van-grid-item__content--center {
	border-radius: 15px;
	padding: 0;
    height: auto;
    display: flex;
    flex-direction: row;
    background: none;
	align-items: flex-start;
}
::v-deep .van-image{
	width: 200px;
    height: 200px;
}
::v-deep .van-grid-item__content::after{
	border: none;
}
::v-deep .van-image__img {
	border-radius: 10px;
	border: 1px solid #ebca70;
}
::v-deep .van-nav-bar__left span {
	font-size: 26px;
	color: #fff;
	margin-left: 10px;
}
::v-deep .van-nav-bar .van-icon {
	font-size: 30px;
	margin-top: 5px;
}
.rig-name {
	font-size: 1.1rem;
	color: #fff;
	flex: 1;
	margin-left: 25px;
	p{
		margin: 0;
		font-size: 24px;
		padding: 10px 0;
		span{
			display: block;
			background: linear-gradient(#C7AE96, #F7E8D1);
			color: #A37458;
			font-size: 26px;
			padding: 0px 20px 0 10px;
			margin-right: 20px;
			border-top-left-radius: 10px;
			border-bottom-right-radius: 10px;
			i{
				background: url(../images/item/ico_rz.png) no-repeat center center;
				background-size: 20px 20px;
				padding: 20px;
			}
		}
	}
	p:first-child{
		display: flex;
	}
	p.btn{
		width: 80%;
		background: linear-gradient(#C7AE96, #F7E8D1);
		color: #000;
		padding: 8px 0;
		border-radius: 40px;
		text-align: center;
		margin-top: 20px;
	}
}
.game_item_img{
	width: 100%;
    height: 8.3rem;
    position: relative;
	overflow: hidden;
	padding: 30px;
}

.mask {
	background-color: rgb(0 0 0 / 0%);
	animation-duration: 0.35s;
}

.play-type-tip {
	position: unset;
	margin: auto;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	max-height: 50%;
	z-index: 10;
	overflow: hidden;
	background-color: #000;
	color: #fff;
}

.play-type-tip .title {
	line-height: 90px;
	text-align: center;
	color: #fff;
	font-size: 35px;
	font-weight: 500;
	position: relative;
	.titleft{
		position: absolute;
		width: 30px;
		height: 30px;
		top:30px;
		left:20px;
	}
}
.classlist{
	padding: 20px 30px;
	height: 100vh;
	ul{
		display: flex;
		flex-wrap: wrap;
		li{
			width: 25%;
			padding: 30px 20px 0 0;
			span{
				background: #EACC9B;
				color: #000;
				text-align: center;
				width: 100%;
				height: 50px;
				display: block;
				border-radius: 10px;
				font-size: 25px;
				line-height: 50px;
				overflow: hidden;
			}
		}
	}
}


.van-popup--center {
	border-radius: 30px;
}
</style>
