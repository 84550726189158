<template>

	<div class="bg-container page">
		<div class="bg-wrapper">
			<div class="register">
				<van-nav-bar class="nav-bar">
					<template #left>
						<van-icon name="arrow-left" color="#fff" @click="back()" />
					</template>
				</van-nav-bar>
				<div class="wrapper">
					<!-- <div class="logo-container">
						<div class="logo-wrapper">
							<img class="logo-img" :src="this.$store.getters.getBaseInfo.ico !== undefined ? this.$store.getters.getBaseInfo.ico : ''">
						</div>
						<div class="fgx">
							{{ $t('auth.hezuo') }}
						</div>
					</div> -->
					<div class="loginForm">
						<div class="infoInput ">
							<div class="userIcon"></div>
							<van-field v-model="username" class="input" :placeholder="$t('auth.username_place')">
							</van-field>
						</div>
							
						<div class="infoInput ">
							<div class="lockIcon"></div>
							<van-field v-model="password" :type="passwordType" input-align="center" class="input"
							:placeholder="$t('auth.pwd_place')">
								<template slot="right-icon">
									<van-icon :name="passwordType === 'password' ? 'closed-eye' : 'eye-o'" @click="switchPasswordType" />
								</template>
							</van-field>
						</div>
						<div class="infoInput ">
							<div class="userIcon"></div>
							<van-field v-model="code" clearable input-align="center" class="input" :placeholder="$t('auth.invite_code_place')" />
						</div>
						<van-button class="login-btn" type="primary" size="normal"
							@click="doRegister()">{{ $t("auth.register") }}</van-button>

						<div @click="toRegister()" class="register-text">{{ $t("auth.has_account") }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	model: {
		prop: 'inputValue',
		event: 'input'
	},
	props: {
		/**
		 * 当前输入的值
		 */
		inputValue: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			checked: true,
			username: '',
			code: '',
			lang: 'en_us',
			password: this.inputValue,
			passwordType: 'password',
		};
	},
	mounted() {
		this.lang = 'ms_my';
	},
	methods: {
		switchPasswordType() {
			this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
		},
		back() {
			this.$router.push("Login");
		},
		toRegister() {
			this.$router.push("Login");
		},
		doRegister() {
			if (this.username === "" || this.username === null || this.username === undefined) {
				this.$toast.fail(this.$t("auth.username_place"));
				return false;
			}
			if (this.password === "" || this.password === null || this.password === undefined) {
				this.$toast.fail(this.$t("auth.pwd_place"));
				return false;
			}
			if (this.code === "" || this.code === null || this.code === undefined) {
				this.$toast.fail(this.$t("auth.invite_code_place"));
				return false;
			}
			// if(!this.checked){
			//   this.$toast.fail(this.$t("auth.agreement"));
			//   return false;
			// }
			this.$http({
				method: 'post',
				data: {
					username: this.username,
					password: this.password,
					code: this.code,
					lang: this.lang
				},
				url: 'member_register'
			}).then(res => {
				if (res.code === 200) {
					this.$toast.success(res.msg);
					localStorage.setItem('token', res.data)
					this.$router.push("Mine")
				} else {
					this.$toast.fail(res.msg);
				}
			})
		}
	},
	created() {
		if (localStorage.getItem('token')) {
			return window.history.back();
		}
	}
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.register {
	height: 100%;
}
.bg-container{
	background: url(../images/reg_bg.png) no-repeat left top;
	background-size: cover;
}
.bg-container .bg-wrapper .register .nav-bar {
	background: 0 0
}

.register .wrapper {
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	margin-top: 250px;
}

.logo-container .logo-wrapper {
	position: relative;
	text-align: center;
}

.logo-container .logo-wrapper .logo-img {
	height: 150px;
}
.logo-container .fgx{
	height: 50px;
	position: relative;
	text-align: center;
	background: url(../../../public/img/login/reg_fgx.png) no-repeat center center;
	background-size: 80% 50%;
	font-size: 25px;
	line-height: 50px;
	color: #E1BB8A;
}
.logo-container .logolist{
	display: flex;
    color: #fff;
    font-size: 28%;
    justify-content: space-around;
    padding-top: 20px;
	div{
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		text-align: center;
		p{
			margin: 0;
			width: 100%;
			img{
				padding: 10px 30px;
				height: 90px;
			}
		}
	}
}

.register .wrapper .title {
	line-height: 100px;
	text-align: center;
	font-size: 45px;
	font-weight: 700;
	color: #fff;
	letter-spacing: 5px;
}

.register .wrapper .loginForm {
	padding: 30px 90px;
}
.register .wrapper .loginForm .infoInput{
	position: relative;
	background: #eaeaeab3;
	border-radius: 50px;
	display: flex;
	justify-content: space-between;
	margin-top: 30px;
}
.register .wrapper .loginForm .infoInput .userIcon{
	background: url(../images/user/ico_user.png) no-repeat;
	background-size: 60px 60px;
	display: block;
    width: 60px;
    height: 60px;
    border-radius: 35px;
	margin: 15px 0 15px 30px;
}
.register .wrapper .loginForm .infoInput .lockIcon{
	background: url(../images/user/ico_lock.png) no-repeat;
	background-size: 60px 60px;
	display: block;
    width: 60px;
    height: 60px;
    border-radius: 35px;
	margin: 15px 0 15px 30px;
}

.register .wrapper .loginForm .infoInput .input {
	padding: 10px 20px;
	text-align: left;
	line-height: 70px;
	font-size: 30px;
	color: #fff;
	background: none;
	flex: 1;
}
::v-deep .van-field__control{
	text-align: left;
	color: #000;
}
::v-deep .van-field__control::-webkit-input-placeholder{
	text-align: left;
	color: #fff;
}
::v-deep .van-field__right-icon .van-icon {
	font-size: 50px;
}

::v-deep .van-icon {
	font-size: 50px;
}

.register .wrapper .loginForm .reset-text {
	margin: 30px 15px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.register .wrapper .loginForm .reset-text span {
	color: #fff;
	font-size: 25px;
	font-weight: 500;
	line-height: 15px;
}

.register .wrapper .loginForm .register-text {
	margin: 20px 0;
	color: #fff;
}

.register .wrapper .loginForm .register-text span {
	color: #fff;
	font-size: 25px;
	font-weight: 500;
	line-height: 20px;
}

.register .wrapper .loginForm .login-btn {
	margin-top: 30px;
	width: 100%;
	height: 100px;
	border-radius: 50px;
	color: #fff;
	background-color: #ffc2004d;
	font-size: 30px;
	border: none;
}

.register .wrapper .loginForm .agreement {
	margin-top: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.register .wrapper .loginForm .agreement .agreement-text {
	margin-left: 10px;
	font-size: 25px;
	color: #fff;
	font-weight: 500;
	line-height: 30px;
}

::v-deep .agreement .van-icon {
	font-size: 30px;
}

::v-deep .agreement .van-checkbox__icon {
	font-size: 38px;
}

::v-deep .agreement .van-checkbox__icon--checked .van-icon {
	color: #fff;
	border-color: rgb(126, 86, 120);
	background-color: rgb(126, 86, 120);
}
</style>
